"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LINKED_TASKS_JQ = exports.FIELDS_TO_COLUMNS = void 0;
exports.FIELDS_TO_COLUMNS = {
    task_title: 'task_title',
    project: 'project',
    status: 'status',
};
exports.LINKED_TASKS_JQ = `(if .data then {
  data: [ 
      .included as $included 
      | .data[] as $data 
      | $data 
      | .relationships["ticketStatus"].data as $ticketStatus 
      | .relationships["job"].data as $job 
      | .attributes 
      | { 
          id: .ticketid, 
          ticket_id: .ticketid, 
          ticket_title: .title, 
          job: (if $job then $included[] ? | select(.type  as $type | .id as $id | $job | select (.id == $id and .type == $type)) | .attributes else null end), 
          ticketStatus: (if $ticketStatus then $included[] ? | select(.type  as $type | .id as $id | $ticketStatus | select (.id == $id and .type == $type)) | .attributes else null end)
      } 
  ], 
  page: .meta.page
} else . end)`;
