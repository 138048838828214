"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeadlineType = void 0;
var DeadlineType;
(function (DeadlineType) {
    DeadlineType["specificDate"] = "specific_date";
    DeadlineType["noDeadline"] = "no_deadline";
    DeadlineType["startDate"] = "stage_start_date";
    DeadlineType["nBusinessDay"] = "n_business_day";
})(DeadlineType = exports.DeadlineType || (exports.DeadlineType = {}));
