"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOCD_VIEW = exports.COLOR_SOCD_BG = exports.REVIEW_GRID_JQ = exports.FIELDS_TO_COLUMNS = exports.PAGE_SIZE = exports.COLUMNS_CONFIGS_KEY_INSIDE_PROJECT = exports.COLUMNS_CONFIGS_KEY_TASK_FORM = exports.COLUMNS_CONFIGS_KEY_NON_PROJECT = void 0;
exports.COLUMNS_CONFIGS_KEY_NON_PROJECT = 'gridColumnsConfigsNonProject';
exports.COLUMNS_CONFIGS_KEY_TASK_FORM = 'gridColumnsConfigsTaskForm';
exports.COLUMNS_CONFIGS_KEY_INSIDE_PROJECT = 'gridColumnsConfigsInsideProject';
exports.PAGE_SIZE = 5;
exports.FIELDS_TO_COLUMNS = {
    name: 'name',
    deadline: 'deadline',
    brand_title: 'brand_title',
    status_order_by: 'status_order_by',
    job_title: 'job_title',
    round_number: 'round_number',
    source: 'source_title',
    comment_count: 'comment_count',
    round_type_order_by: 'round_type_order_by',
    members: 'member_count',
    created_at: 'created_at',
};
exports.REVIEW_GRID_JQ = `(if .data then { data:  
  [ 
    .data[] as $data | $data 
    | .attributes | 
    { 
      name: .name,  
      reviewers: .reviewers,
      progress: .progress, 
      source: (.round_sources[0] as $source | if $source then $source else null end ),
      round_number: .round_name, 
      round_id: .round_id,
      deadline: .deadline, 
      round_type_order_by: .round_type, 
      status_order_by: .status, 
      comment_count: .comment_count,
      job_id: .job_id, 
      job_title: .job_title, 
      brand_title: .job_brand,
      job_number: .job_number,
      created_at: .created_at,
      last_round_number: .last_round_number,
      manage: .manage,
      round_title: .round_title,
      round_status: .round_status,
      round_stage_id: .round_stage_id,
      status: .status,
      decision_status_label: .round_decision_status_label,
      round_thumbnail_link: .round_thumbnail_link,
      review_id: .review_id,
      related_rounds : .related_rounds,
      owner_id: .owner_id
    } 
  ], 
  page: .meta.page 
} else . end)`;
exports.COLOR_SOCD_BG = {
    white: '#FFF',
    grey: '#F5F6FA',
};
exports.SOCD_VIEW = {
    review: 'review',
    stage: 'stage',
    userDecision: 'user decision',
};
