"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TEMPLATE_RESOURCE = exports.TEMPLATE_PERMISSION = exports.PERMISSION_TYPE = exports.SAVING_METHOD = void 0;
var SAVING_METHOD;
(function (SAVING_METHOD) {
    SAVING_METHOD["saveAsNew"] = "saveAsNew";
    SAVING_METHOD["updateExisting"] = "updateExisting";
})(SAVING_METHOD = exports.SAVING_METHOD || (exports.SAVING_METHOD = {}));
var PERMISSION_TYPE;
(function (PERMISSION_TYPE) {
    PERMISSION_TYPE["userView"] = "userView";
    PERMISSION_TYPE["officeView"] = "officeView";
    PERMISSION_TYPE["brandCategoriesView"] = "brandCategoriesView";
    PERMISSION_TYPE["brandView"] = "brandView";
    PERMISSION_TYPE["userEdit"] = "userEdit";
})(PERMISSION_TYPE = exports.PERMISSION_TYPE || (exports.PERMISSION_TYPE = {}));
var TEMPLATE_PERMISSION;
(function (TEMPLATE_PERMISSION) {
    TEMPLATE_PERMISSION["view"] = "view";
    TEMPLATE_PERMISSION["edit"] = "edit";
})(TEMPLATE_PERMISSION = exports.TEMPLATE_PERMISSION || (exports.TEMPLATE_PERMISSION = {}));
var TEMPLATE_RESOURCE;
(function (TEMPLATE_RESOURCE) {
    TEMPLATE_RESOURCE["user"] = "user";
    TEMPLATE_RESOURCE["userGroup"] = "usergroup";
    TEMPLATE_RESOURCE["brand"] = "brand";
    TEMPLATE_RESOURCE["brandCategory"] = "brandcategory";
    TEMPLATE_RESOURCE["client"] = "client";
})(TEMPLATE_RESOURCE = exports.TEMPLATE_RESOURCE || (exports.TEMPLATE_RESOURCE = {}));
