"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.addZiflowActivity = exports.deleteVersionComments = exports.setIsLoadingComments = exports.commentSlice = exports.fetchZiflowActivities = exports.fetchAssetComments = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const utilities_1 = __importStar(require("jsAssets/utilities"));
const pulse_components_1 = require("@pcs/pulse-components");
const helpers_1 = require("../helpers");
const initialState = {
    comments: [],
    isLoadingComments: false,
    assetId: undefined,
    versions: [],
    versionComments: [],
    nodeId: undefined,
    ziflowActivities: [],
};
exports.fetchAssetComments = (0, toolkit_1.createAsyncThunk)('assetComment/fetchById', async ({ assetId }) => {
    const payload = {
        action: 'files',
        type: 'comments',
        assetId,
    };
    if (!assetId) {
        throw new Error('Failed to load asset comments');
    }
    const response = await pulse_components_1.v2Endpoint.get(helpers_1.URLS.api, { params: payload });
    if (!response.data) {
        utilities_1.default.notification.danger('Failed to fetch asset comment');
    }
    return (0, helpers_1.notesMapper)(response.data.data);
});
exports.fetchZiflowActivities = (0, toolkit_1.createAsyncThunk)('assetComment/fetchZiflow', async ({ roundId }) => {
    const response = await pulse_components_1.v2Endpoint
        .get(`${helpers_1.URLS.app}api/rounds/${roundId}/review-activity-history`)
        .then((res) => {
        const { data } = res;
        const activities = (0, utilities_1.parseJsonApi)((0, utilities_1.camelizeKeys)(data));
        return activities;
    });
    return response;
});
exports.commentSlice = (0, toolkit_1.createSlice)({
    name: 'Comment',
    initialState,
    reducers: {
        setIsLoadingComments: (state) => {
            state.isLoadingComments = true;
        },
        setComments: (state, action) => {
            state.comments = action.payload;
            state.isLoadingComments = false;
        },
        addZiflowActivity: (state, action) => {
            state.ziflowActivities = [...state.ziflowActivities, action.payload];
        },
        deleteVersionComments: (state, action) => {
            const filterComments = state.versionComments
                .filter((comment) => {
                return comment.id !== action.payload;
            })
                .map((comment) => {
                var _a;
                return {
                    ...comment,
                    childTaskNotes: (_a = comment === null || comment === void 0 ? void 0 : comment.childTaskNotes) === null || _a === void 0 ? void 0 : _a.filter((child) => {
                        return (child === null || child === void 0 ? void 0 : child.id) !== action.payload;
                    }),
                };
            });
            state.versionComments = filterComments;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.fetchAssetComments.pending, (state) => {
            state.isLoadingComments = true;
        });
        builder.addCase(exports.fetchAssetComments.fulfilled, (state, action) => {
            state.comments = action.payload;
            state.isLoadingComments = false;
        });
        builder.addCase(exports.fetchAssetComments.rejected, (state) => {
            state.isLoadingComments = false;
        });
        builder.addCase(exports.fetchZiflowActivities.pending, (state) => {
            state.isLoadingComments = true;
        });
        builder.addCase(exports.fetchZiflowActivities.fulfilled, (state, action) => {
            state.isLoadingComments = false;
            state.ziflowActivities = action.payload;
        });
        builder.addCase(exports.fetchZiflowActivities.rejected, (state) => {
            state.isLoadingComments = false;
            utilities_1.default.notification.danger('Fail to get ziflow activities');
        });
    },
});
_a = exports.commentSlice.actions, exports.setIsLoadingComments = _a.setIsLoadingComments, exports.deleteVersionComments = _a.deleteVersionComments, exports.addZiflowActivity = _a.addZiflowActivity;
exports.default = exports.commentSlice.reducer;
