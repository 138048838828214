"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const NOTIFICATION = {
    // load
    loadedRound: 'Round Loaded',
    // created
    createdReview: 'Review created.',
    savedTemplate: 'Review Template saved.',
    // updated
    updatedTemplatePermission: 'Review Template permissions updated.',
    updatedRound: 'Round updated.',
    updatedNewRound: 'New Round updated.',
    // deleted
    deletedRound: 'Round cancelled.',
    deletedTemplate: 'Review Template deleted.',
    // failed
    saveReviewFailed: 'Failed to save Review.',
    saveTemplateFailed: 'Failed to save Review Template.',
    loadTemplatePermissionFailed: 'Failed to load Review Template permissions.',
    loadRoundFailed: 'Failed to load Round.',
    updateTemplatePermissionFailed: 'Failed to update Review Template permissions.',
    updateRoundFailed: 'Failed to update Round.',
    updateNewRoundFailed: 'Failed to update new Round.',
    // require
    requireEmailMessage: 'Email subject & message are required.',
    requireReviewer: 'Stages must have at least one Reviewer.',
    // sent email
    sentMailReviewer: (amountReviewer = 1) => `Notification sent to ${amountReviewer} Reviewer${amountReviewer > 1 ? 's' : ''}`,
    // duplicate
    uniqueStageName: 'Stage names cannot be identical.',
    // existing
    existedEmail: 'The email is already listed.',
};
exports.default = NOTIFICATION;
