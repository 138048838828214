"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAvailableReviewZiflow = exports.setBlockRound = exports.setIdNewRound = exports.setReviewRound = exports.reviewRoundSlice = exports.updateReviewDecision = exports.updateReviewInstruction = exports.fetchUpdateReviewRounds = exports.fetchReviewRounds = exports.fetchReviewTasks = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const utilities_1 = __importStar(require("jsAssets/utilities"));
const pulse_components_1 = require("@pcs/pulse-components");
const helpers_1 = require("../helpers");
exports.fetchReviewTasks = (0, toolkit_1.createAsyncThunk)('Review/fetchReviewTasks', async ({ roundId }) => {
    const endpoint = `${helpers_1.URLS.pulse}v2/api/rounds/${roundId}/tickets`;
    const response = await pulse_components_1.v2Endpoint
        .get(endpoint, {
        params: {
            include: 'users,ticketStatus,reportedBy,tags,type',
        },
    })
        .then((res) => {
        const { data } = res;
        return data;
    });
    return response;
});
exports.fetchReviewRounds = (0, toolkit_1.createAsyncThunk)('Review/fetchById', async ({ roundId }) => {
    if (!roundId) {
        throw new Error('Missing review round ID');
    }
    const endpoint = `${helpers_1.URLS.pulse}v2/api/rounds/${roundId}`;
    const response = await pulse_components_1.v2Endpoint.get(endpoint).then((res) => {
        const { data } = res;
        return data;
    });
    delete response.updated_at;
    return response;
});
exports.fetchUpdateReviewRounds = (0, toolkit_1.createAsyncThunk)('Review/updateById', async ({ roundId }) => {
    if (!roundId) {
        throw new Error('Missing review round ID');
    }
    const endpoint = `${helpers_1.URLS.pulse}v2/api/rounds/${roundId}`;
    const response = await pulse_components_1.v2Endpoint.get(endpoint).then((res) => {
        const { data } = res;
        return data;
    });
    delete response.updated_at;
    return response;
});
exports.updateReviewInstruction = (0, toolkit_1.createAsyncThunk)('Review/updateReviewInstruction', async ({ newInstruction, roundId }) => {
    const endpoint = `${helpers_1.URLS.pulse}v2/api/rounds/${roundId}`;
    await pulse_components_1.v2Endpoint.request({
        method: pulse_components_1.METHODS.PATCH,
        url: endpoint,
        data: {
            data: {
                type: 'rounds',
                id: `${roundId}`,
                attributes: { instruction: newInstruction },
            },
        },
        headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
        },
    });
});
exports.updateReviewDecision = (0, toolkit_1.createAsyncThunk)('Review/updateDecision', async ({ member, stageId, decisionId, completed }) => {
    const url = `${helpers_1.URLS.pulse}v2/api/round-stages/${stageId}/reviewers/${member}/status`;
    await pulse_components_1.v2Endpoint.request({
        method: pulse_components_1.METHODS.PATCH,
        url,
        data: {
            decision_status_id: Number(decisionId),
            completed,
        },
        headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
        },
    });
});
const initialState = {
    reviewRound: null,
    tasks: [],
    isLoadingReviewRound: false,
    idNewRound: 0,
    blockRound: false,
    availableReviewZiflow: false,
};
exports.reviewRoundSlice = (0, toolkit_1.createSlice)({
    name: 'Review',
    initialState,
    reducers: {
        setReviewRound: (state, action) => {
            state.reviewRound = action.payload;
        },
        setIdNewRound: (state, action) => {
            state.idNewRound = action.payload;
        },
        setBlockRound: (state, action) => {
            state.blockRound = action.payload;
        },
        setAvailableReviewZiflow: (state, action) => {
            state.availableReviewZiflow = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchReviewRounds.pending, (state) => {
            state.isLoadingReviewRound = true;
        })
            .addCase(exports.fetchReviewRounds.fulfilled, (state, action) => {
            state.reviewRound = (0, utilities_1.camelizeKeys)((0, utilities_1.parseJsonApi)(action.payload));
            state.isLoadingReviewRound = false;
        })
            .addCase(exports.fetchUpdateReviewRounds.fulfilled, (state, action) => {
            state.reviewRound = (0, utilities_1.camelizeKeys)((0, utilities_1.parseJsonApi)(action.payload));
        })
            .addCase(exports.fetchUpdateReviewRounds.rejected, () => {
            utilities_1.default.notification.danger('Failed to fetch Review Round');
        })
            .addCase(exports.fetchReviewTasks.pending, (state) => {
            state.isLoadingReviewRound = true;
        })
            .addCase(exports.fetchReviewTasks.fulfilled, (state, action) => {
            state.tasks = (0, utilities_1.parseJsonApi)(action.payload);
            state.isLoadingReviewRound = false;
        })
            .addCase(exports.fetchReviewTasks.rejected, (state) => {
            utilities_1.default.notification.danger('Failed to fetch Review Round');
        })
            .addCase(exports.updateReviewInstruction.fulfilled, (state) => {
            state.isLoadingReviewRound = false;
            utilities_1.default.notification.success('Updated review instructions success');
        })
            .addCase(exports.updateReviewInstruction.rejected, (state) => {
            state.isLoadingReviewRound = false;
            utilities_1.default.notification.danger('Failed to update Review Round');
        })
            .addCase(exports.updateReviewDecision.pending, (state) => {
            state.isLoadingReviewRound = true;
            utilities_1.default.blockPage();
        })
            .addCase(exports.updateReviewDecision.fulfilled, (state) => {
            state.isLoadingReviewRound = false;
            utilities_1.default.notification.success('Updated review decision success');
            utilities_1.default.unblockPage();
        })
            .addCase(exports.updateReviewDecision.rejected, (state) => {
            state.isLoadingReviewRound = false;
            utilities_1.default.notification.danger('Failed to update Review Decision');
            utilities_1.default.unblockPage();
        });
    },
});
_a = exports.reviewRoundSlice.actions, exports.setReviewRound = _a.setReviewRound, exports.setIdNewRound = _a.setIdNewRound, exports.setBlockRound = _a.setBlockRound, exports.setAvailableReviewZiflow = _a.setAvailableReviewZiflow;
exports.default = exports.reviewRoundSlice.reducer;
