"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const sliceReview_1 = require("./sliceReview");
const sliceTemplate_1 = require("./sliceTemplate");
const reviewSlice = (state) => state.review || sliceReview_1.reviewModalInitialState;
const templateSlice = (state) => state.template || sliceTemplate_1.initialTemplateModalState;
const selectReview = (0, toolkit_1.createSelector)(reviewSlice, templateSlice, (stateReview, stateTemplate) => ({
    ...stateReview,
    ...stateTemplate,
}));
exports.default = selectReview;
