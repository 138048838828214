"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REVIEW_STAGES_CURRENT_USER = exports.REVIEW_TYPE = exports.INITIAL_MEMBER = exports.INITIAL_ROUND = exports.INITIAL_OWNER = exports.newStageDefault = void 0;
const helper_1 = require("../../../../reviews/helper");
const mockData_1 = require("./ReviewStage/StageSettings/mockData");
const newStageDefault = (stageNumber = 1) => ({
    isOpen: true,
    nameStage: `Stage ${stageNumber}`,
    members: [],
    deadlineType: mockData_1.DEADLINE[0].value,
    deadline: null,
    progress: {
        notified: false,
        opened: false,
        commented: false,
        completed: false,
    },
    status: 'not_started',
    statusLabel: 'Not started',
    commentCount: 0,
});
exports.newStageDefault = newStageDefault;
exports.INITIAL_OWNER = {
    ownerId: parseInt(helper_1.USER.id),
    ownerName: helper_1.USER === null || helper_1.USER === void 0 ? void 0 : helper_1.USER.name,
};
exports.INITIAL_ROUND = {
    reviewId: 12,
    ...exports.INITIAL_OWNER,
    roundId: 33,
    roundName: 1,
    roundTypeId: 6,
    roundType: 'internal',
    roundTypeLabel: 'Internal',
};
exports.INITIAL_MEMBER = {
    isWrench: true,
    progress: {
        notified: false,
        opened: false,
        commented: false,
        completed: false,
    },
    view: true,
    share: false,
    comment: true,
    decision: false,
    manage: false,
};
exports.REVIEW_TYPE = [
    {
        value: 'internal',
        label: 'Internal',
    },
    {
        value: 'external',
        label: 'External',
    },
];
exports.REVIEW_STAGES_CURRENT_USER = [
    {
        isOpen: true,
        nameStage: 'Stage 1',
        members: [
            {
                id: parseInt(helper_1.USER.id),
                name: helper_1.USER.name,
                email: helper_1.USER.email,
                clientName: helper_1.USER.clientname,
                category: helper_1.USER.category,
                role: helper_1.USER.role,
                isWrench: true,
                progress: {
                    notified: false,
                    opened: false,
                    commented: false,
                    completed: false,
                },
                view: true,
                share: false,
                comment: true,
                decision: false,
                manage: true,
                avatarUrl: helper_1.USER.avatarUrl,
            },
        ],
        ...exports.INITIAL_OWNER,
        deadlineType: mockData_1.DEADLINE[0].value,
        deadline: null,
        progress: {
            notified: false,
            opened: false,
            commented: false,
            completed: false,
        },
        status: 'not_started',
        statusLabel: 'Not started',
        commentCount: 0,
        isDefaultStage: true,
    },
];
