"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STAGE_STATUS = exports.STAGE_STATUS_LABEL = void 0;
exports.STAGE_STATUS_LABEL = {
    in_progress: 'in progress',
    not_started: 'not started',
    completed: 'completed',
    view_only: 'view only',
    locked: 'locked',
};
exports.STAGE_STATUS = {
    inProgress: 'in_progress',
    notStarted: 'not_started',
    completed: 'completed',
    viewOnly: 'view_only',
    locked: 'locked',
};
