// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.zejoQ02cs5.zejoQ02cs5 {\n  text-transform: none;\n  margin: 0;\n  padding-left: 0;\n  letter-spacing: normal;\n}\nbutton.zejoQ02cs5.zejoQ02cs5:hover,\nbutton.zejoQ02cs5.zejoQ02cs5:focus,\nbutton.zejoQ02cs5.zejoQ02cs5:active {\n  background: inherit;\n  color: inherit;\n}\n._3Ov2ZiOb8f._3Ov2ZiOb8f {\n  background-color: #e5e5e5;\n  height: 32px;\n  flex: 1;\n  max-height: none;\n  margin: 0;\n  padding: 4px;\n}\n._11wiW6Pp28 {\n  max-width: 50px;\n}\n._11wiW6Pp28 input {\n  text-align: center;\n}\n._1lc1GtrzI9 label {\n  font-size: 12px;\n}\n.Bf2vr-EChP {\n  margin-bottom: 10px;\n}\n._1S8AUaaetz label {\n  line-height: normal;\n  display: flex;\n  align-items: center;\n  margin-bottom: 10px;\n}\n._1S8AUaaetz label input {\n  margin-bottom: 0;\n}\n._1S8AUaaetz + ._1S8AUaaetz {\n  margin-top: 10px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"advanced-settings__textButtonRoot": "zejoQ02cs5",
	"advanced-settings__inputAdornmentRoot": "_3Ov2ZiOb8f",
	"advanced-settings__maxRoundsRoot": "_11wiW6Pp28",
	"advanced-settings-section__innerCtn": "_1lc1GtrzI9",
	"advanced-settings__pulseSelectBaseRoot": "Bf2vr-EChP",
	"advanced-settings-section__root": "_1S8AUaaetz"
};
export default ___CSS_LOADER_EXPORT___;
