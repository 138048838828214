"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.METHOD = exports.TITLE = exports.MODE_LABEL = exports.SOURCE_TYPE = exports.PHASE = exports.SAVE_AS_TEMPLATE_OPTION = exports.OPTION = exports.MODE = void 0;
var MODE;
(function (MODE) {
    MODE["create"] = "create-review";
    MODE["share"] = "share-for-review";
    MODE["newRound"] = "create-new-round";
    MODE["update"] = "update-review";
})(MODE = exports.MODE || (exports.MODE = {}));
var OPTION;
(function (OPTION) {
    OPTION["previous"] = "previous";
    OPTION["existing"] = "existing";
    OPTION["upload"] = "upload";
    OPTION["url"] = "url";
})(OPTION = exports.OPTION || (exports.OPTION = {}));
var SAVE_AS_TEMPLATE_OPTION;
(function (SAVE_AS_TEMPLATE_OPTION) {
    SAVE_AS_TEMPLATE_OPTION["new"] = "saveAsNew";
    SAVE_AS_TEMPLATE_OPTION["existing"] = "updateExisting";
})(SAVE_AS_TEMPLATE_OPTION = exports.SAVE_AS_TEMPLATE_OPTION || (exports.SAVE_AS_TEMPLATE_OPTION = {}));
var PHASE;
(function (PHASE) {
    PHASE["startReview"] = "startReview";
    PHASE["confirmCreateNewRound"] = "confirmCreateNewRound";
    PHASE["selectOption"] = "selectOption";
    PHASE["chooseExisting"] = "chooseExisting";
    PHASE["chooseUrl"] = "chooseUrl";
    PHASE["chooseUpload"] = "chooseUpload";
    PHASE["reviewing"] = "reviewing";
    PHASE["savingTemplate"] = "savingTemplate";
    PHASE["templateSettings"] = "templateSettings";
    PHASE["updateTemplate"] = "updateTemplate";
})(PHASE = exports.PHASE || (exports.PHASE = {}));
var SOURCE_TYPE;
(function (SOURCE_TYPE) {
    SOURCE_TYPE["asset"] = "asset";
    SOURCE_TYPE["webDynamic"] = "web-dynamic";
    SOURCE_TYPE["webStatic"] = "web-static";
})(SOURCE_TYPE = exports.SOURCE_TYPE || (exports.SOURCE_TYPE = {}));
// payload type
exports.MODE_LABEL = {
    [MODE.create]: 'create review',
    [MODE.newRound]: 'create review',
    [MODE.share]: 'share for review',
    [MODE.update]: 'share for review',
};
exports.TITLE = {
    [PHASE.startReview]: 'SELECT PROJECT',
    [PHASE.selectOption]: 'CHOOSE REVIEW TYPE',
    [PHASE.chooseExisting]: 'SELECT PROJECT ASSET',
    [PHASE.chooseUpload]: 'UPLOAD ASSET',
    [PHASE.chooseUrl]: 'WEBSITE/URL',
    [PHASE.confirmCreateNewRound]: 'SELECT PROJECT',
    [PHASE.reviewing]: 'Review settings',
    [PHASE.savingTemplate]: 'Save as Template',
    [PHASE.templateSettings]: 'Template Settings',
    [PHASE.updateTemplate]: 'UPDATE TEMPLATE SETTINGS',
};
var METHOD;
(function (METHOD) {
    METHOD["startReview"] = "start-review";
    METHOD["updateReview"] = "update-review";
})(METHOD = exports.METHOD || (exports.METHOD = {}));
