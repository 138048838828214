// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NpjsKUFaoV {\n  max-width: 208px;\n}\n.pMoPRenPa3 > div:first-child {\n  left: 86px !important;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notify-root": "NpjsKUFaoV",
	"notify-root__menuCtn": "pMoPRenPa3"
};
export default ___CSS_LOADER_EXPORT___;
