"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecisionStatus = void 0;
var DecisionStatus;
(function (DecisionStatus) {
    DecisionStatus["inProgress"] = "in_progress";
    DecisionStatus["changesRequired"] = "changes_required";
    DecisionStatus["approvedWithChanged"] = "approved_with_changes";
    DecisionStatus["approved"] = "approved";
    DecisionStatus["notRelevant"] = "not_relevant";
})(DecisionStatus = exports.DecisionStatus || (exports.DecisionStatus = {}));
