"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REVIEW_GRID_JQ = exports.FIELDS_TO_COLUMNS = void 0;
exports.FIELDS_TO_COLUMNS = {
    round_title: 'source_title',
    round_number: 'round_number',
    created_at: 'created_at',
};
exports.REVIEW_GRID_JQ = `(if .data then { data:  
  [ 
    .data[] as $data | $data 
    | .attributes | 
    { 
      id: .review_id,
      name: .name,
      source: (.round_sources[0] as $source | if $source then $source else null end ),
      round_number: .round_name, 
      round_id: .round_id,
      created_at: .created_at,
      round_title: .round_title,
      round_stage_id: .round_stage_id,
      review_id: .review_id,
      round_thumbnail_link: .round_thumbnail_link,
    } 
  ], 
  page: .meta.page 
} else . end)`;
