"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEADLINE = exports.FINAL_STATUS_CALCULATION = void 0;
const stage_settings_types_1 = require("./stage-settings-types");
exports.FINAL_STATUS_CALCULATION = [
    {
        label: 'All Decisions',
        value: 'all_decisions',
    },
    {
        label: 'Only one decision required',
        value: 'only_one_decision_required',
    },
    {
        label: 'Decision made by user',
        value: 'decision_made_by_user',
    },
];
const DEADLINE1 = [
    {
        label: 'No Deadline',
        value: 'no_deadline',
        type: stage_settings_types_1.DeadlineType.noDeadline,
    },
    {
        label: 'Specific Date',
        value: 'specific_date',
        type: stage_settings_types_1.DeadlineType.specificDate,
    },
    {
        label: 'Stage Start Day',
        value: 'stage_start_date',
        type: stage_settings_types_1.DeadlineType.startDate,
    },
    {
        label: '1 Business Day',
        value: '1_business_day',
        type: stage_settings_types_1.DeadlineType.nBusinessDay,
    },
];
const DEADLINE2 = Array(19)
    .fill(2)
    .map((item, index) => {
    return {
        label: `${item + index} Business Days`,
        value: `${item + index}_business_days`,
        type: stage_settings_types_1.DeadlineType.nBusinessDay,
    };
});
exports.DEADLINE = [...DEADLINE1, ...DEADLINE2];
