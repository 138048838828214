// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3V_ngesC7i {\n  padding: 4px 8px;\n  height: auto;\n}\n._3V_ngesC7i [data-testid=\"pcs-circular-spinner\"] {\n  width: 10px;\n  height: 10px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asset-advance__button": "_3V_ngesC7i"
};
export default ___CSS_LOADER_EXPORT___;
