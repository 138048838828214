"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZIFLOW_ACTIVITY_TYPE = void 0;
var ZIFLOW_ACTIVITY_TYPE;
(function (ZIFLOW_ACTIVITY_TYPE) {
    ZIFLOW_ACTIVITY_TYPE["PROOF_CREATED"] = "PROOF_CREATED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_NEW_VERSION_CREATED"] = "PROOF_NEW_VERSION_CREATED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_PROCESSED"] = "PROOF_PROCESSED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_OPENED"] = "PROOF_OPENED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_STATUS_CHANGED"] = "PROOF_STATUS_CHANGED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_COMMENT_ADDED"] = "PROOF_COMMENT_ADDED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_COMMENT_CHANGED"] = "PROOF_COMMENT_CHANGED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_COMMENT_DELETED"] = "PROOF_COMMENT_DELETED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_REPLY_ADDED"] = "PROOF_REPLY_ADDED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_REPLY_CHANGED"] = "PROOF_REPLY_CHANGED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_REPLY_DELETED"] = "PROOF_REPLY_DELETED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_DECISIONS"] = "PROOF_DECISIONS";
    ZIFLOW_ACTIVITY_TYPE["PROOF_CHANGED"] = "PROOF_CHANGED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_UPDATED"] = "PROOF_UPDATED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_FAILED"] = "PROOF_FAILED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_MENTIONS"] = "PROOF_MENTIONS";
    ZIFLOW_ACTIVITY_TYPE["PROOF_STAGE_STARTED"] = "PROOF_STAGE_STARTED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_REVIEW_COMPLETED"] = "PROOF_REVIEW_COMPLETED";
    ZIFLOW_ACTIVITY_TYPE["PROOF_DELETED"] = "PROOF_DELETED";
})(ZIFLOW_ACTIVITY_TYPE = exports.ZIFLOW_ACTIVITY_TYPE || (exports.ZIFLOW_ACTIVITY_TYPE = {}));
exports.default = ZIFLOW_ACTIVITY_TYPE;
