"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateReviewModalContext = void 0;
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const PulseModalReview = react_1.default.lazy(() => Promise.resolve().then(() => __importStar(require('../../../reviews-modal'))));
exports.CreateReviewModalContext = (0, react_1.createContext)({
    setGridAction: null,
    gridAction: null,
    setOpenReviewModal: null,
    openReviewModal: null,
});
const ReviewModalContext = ({ children, ...restProps }) => {
    const [openReviewModal, setOpenReviewModal] = react_1.default.useState(false);
    (0, react_1.useEffect)(() => {
        setOpenReviewModal(restProps.openReviewModal);
    }, [restProps.openReviewModal]);
    const handleSetOpenReviewModal = (open) => {
        if (restProps.setOpenReviewModal) {
            restProps.setOpenReviewModal(open);
        }
        setOpenReviewModal(open);
    };
    return (react_1.default.createElement(exports.CreateReviewModalContext.Provider, { value: {
            setOpenReviewModal: handleSetOpenReviewModal,
            openReviewModal,
            ...restProps,
        } },
        (0, lodash_1.isFunction)(children) ? children === null || children === void 0 ? void 0 : children(handleSetOpenReviewModal) : children,
        openReviewModal && (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement("div", null, "Loading...") },
            react_1.default.createElement(PulseModalReview, { onCloseReviewModal: () => handleSetOpenReviewModal(false) })))));
};
exports.default = ReviewModalContext;
