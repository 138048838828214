// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ibFU431mbu {\n  max-height: 250px;\n  overflow-y: scroll;\n}\n.ibFU431mbu .b-grid-row {\n  font-size: 11px;\n}\n.ibFU431mbu ._1YVTKyvB26 {\n  overflow-y: auto;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ticketHistory__CollapseRoot": "ibFU431mbu",
	"ticketHistory__descriptionCell": "_1YVTKyvB26"
};
export default ___CSS_LOADER_EXPORT___;
